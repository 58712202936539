import { call, put, takeLatest } from 'redux-saga/effects'
import {
  UPDATE_SELECTED_COLUMNS,
  STORE_SELECTED_COLUMNS,
  FETCH_SELECTED_COLUMNS,
} from '../../constants/Tables/Common'
import {
  fetchTableColumns,
  updateSelectedColumnsInDB,
} from '../../../firebase/fireStore/tableColumnDBMethods'
import { CONNECTION_STATUS } from 'constants/Constant'

function* fetchTableColumnsList(action) {
  try {
    let selectedDbCols = yield call(fetchTableColumns, action)

    if (action.tableName === 'myDevices' && !selectedDbCols.includes(CONNECTION_STATUS.PRESENCE_STATUS)) {
      selectedDbCols = [...selectedDbCols, CONNECTION_STATUS.PRESENCE_STATUS];
    }

    let resPayload = {
      type: UPDATE_SELECTED_COLUMNS,
      tableName: action.tableName,
    }
    if (selectedDbCols.length) {
      resPayload.updatedTableColObj = {
        ...action.tableColObj,
        selectedColumns: selectedDbCols,
        isFirstRun: false,
      }
    } else {
      const allCols = action.tableColObj.allColumns.map((obj) => obj.value)
      const hiddenCols = action.tableColObj.hiddenColumns.map((obj) => obj.value)
      const defaultSelectedCols = allCols.filter(item => !hiddenCols.includes(item))
      resPayload.updatedTableColObj = {
        ...action.tableColObj,
        selectedColumns: defaultSelectedCols,
        isFirstRun: false,
      }
    }
    yield put(resPayload)
  } catch (error) {
    console.error('fetchTableColumns err', error)
    yield put({ type: UPDATE_SELECTED_COLUMNS, error })
  }
}

function* storeTableColumnsList(action) {
  try {
    const selectedDbCols = yield call(updateSelectedColumnsInDB, action)
    let resPayload = {
      type: UPDATE_SELECTED_COLUMNS,
      tableName: action.tableName,
    }
    resPayload.updatedTableColObj = {
      ...action.tableColObj,
      selectedColumns: selectedDbCols,
      isFirstRun: false,
    }
    yield put(resPayload)
  } catch (error) {
    console.error('storeTableColumnsList err', error)
    yield put({ type: UPDATE_SELECTED_COLUMNS, error })
  }
}

export function* fetchTableColumnListSaga() {
  yield takeLatest(FETCH_SELECTED_COLUMNS, fetchTableColumnsList)
}

export function* updateTableColumnListSaga() {
  yield takeLatest(STORE_SELECTED_COLUMNS, storeTableColumnsList)
}
